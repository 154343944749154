//TODO: 用storyblok做掉

import { fetchBundleContent, fetchProduct } from "./Storyblok/fetchStory";
import { PriceStoryblok } from "./StoryBlockSchema/components-schema";

// export type Price = {
//   TWD: number;
//   USD: number;
//   // Is_TWD_Standard: boolean;
//   discountPercent?: number;
// };

//Deprecated
export type PricePack = {
  [key: string]: PriceData;
  TWD: PriceData;
  USD: PriceData;
};

// 訂單打平用??
export type PriceData = {
  title?: string; //還要多國語言喔
  // currency: string;
  price?: number; //what purppose?//募資品不需要
  discountPercent?: number;
  // discountedPrice?: number; // 就不用算兩次
};

export type BundleData = {
  title: string; //No locale
  includes: PricePack[];
  discountPercent: number;
};
//TODO: price only???

//tax
//item name
const detention: PricePack = {
  TWD: {
    title: "返校 DETENTION 數位下載版",
    price: 299,
    discountPercent: 0,
  },
  USD: {
    title: "DETENTION",

    price: 11.99,
    discountPercent: 0,
  },
};
const detention_ost: PricePack = {
  TWD: {
    title: "返校 DETENTION 遊戲原聲帶 ",
    price: 199,
    discountPercent: 0,
  },
  USD: {
    title: "DETENTION - Original Soundtracks ",
    price: 6.99,
    discountPercent: 0,
  },
};
const devotion: PricePack = {
  TWD: {
    title: "還願 DEVOTION 數位下載版",
    price: 499,
    discountPercent: 0,
  },
  USD: { title: "DEVOTION", price: 16.99, discountPercent: 0 },
};
const devotion_ost: PricePack = {
  TWD: {
    title: "還願 DEVOTION 遊戲原聲帶",
    price: 149,
    discountPercent: 0,
  },
  USD: {
    title: "DEVOTION - Original Soundtracks",
    price: 5.99,
    discountPercent: 0,
  },
};

const detention_bundle: BundleData = {
  title: "Detention Bundle",
  includes: [detention, detention_ost],
  discountPercent: 10,
};
const devotion_bundle: BundleData = {
  title: "Devotion Bundle",
  includes: [devotion, devotion_ost],
  discountPercent: 10,
};
const devote_bundle: BundleData = {
  title: "Devote Bundle",
  includes: [devotion, devotion_ost, detention, detention_ost],
  discountPercent: 20,
};

const bundleDict = { detention_bundle, devotion_bundle, devote_bundle };
const productPricePriceDict = {
  detention,
  devotion,
  detention_ost,
  devotion_ost,
};
export function getLocalPrice(pid: string): Promise<PricePack> {
  return productPricePriceDict[pid];
}
//怎麼樣給價格...單一價格，包進去? 還是另外的data?
//Pledge: price
//Product: 目前沒有Price 可以有?
//不該一個個拿，可以全部一起拿...

//從storyblok拿價格喔
export async function getPrice(uuid: string, locale = "default") {
  //舊的?
  //TODO: Locale
  const product = await fetchProduct({ uuid, locale });
  return {
    productStory: product,
    product: product.content,
    price: product.content.price[0],
  };
}

// export async function getLocalBundlePrice(  bundle_id: string){
//   const bundle = bundleDict[bundle_id] as BundleData;

//   //每個幣都要弄!
//   for (var currency of ["TWD", "USD"]) {
//     for (var item of bundle.includes) {
//       const priceData = item[currency];
//       total[currency].price += priceData.price;
//     }
//     total[currency].discountPercent = bundle.discountPercent;
//     total[currency].price = PriceRounding(total[currency].price, currency);
//   }
//   return total;
// }
//拿到bundle的價格，
//TODO: Locale
//getBundleDisplayPrice?? 原價？ 加總？
export async function getBundlePrice({
  bundle_slug,
  locale,
}: {
  bundle_slug: string;
  locale: string;
}) {
  const bundleBlok = await fetchBundleContent(bundle_slug, locale);

  //TODO: 其實可以先算好總價...不用每次撈products出來加總？
  //這樣就和每個單品價格分開了

  // var bundlePrice: PriceStoryblok = {
  //   TWD: 0,
  //   USD: 0,
  //   discountPercent: 0,
  //   component: "Price",
  //   _uid: "--",
  // };

  // for (var currency of ["TWD", "USD"]) {
  //   for (var productLink of bundleBlok.includes) {
  //     var product = productLink.link.story.content.product[0];
  //     //product 的uuid
  //     productLink.link.story.uuid;
  //     bundlePrice[currency] += product.price[0][currency];
  //   }
  //   bundlePrice[currency].discountPercent = bundleBlok.discountPercent;
  //   bundlePrice[currency] = PriceRounding(bundlePrice[currency], currency);
  // }

  return { bundle: bundleBlok, bundlePrice: bundleBlok.price[0] };
}
export function PriceRounding(price: number, currency: string) {
  price = +price;
  if (currency == "TWD") return Math.round(price);
  else if (currency == "USD") return Math.round(price * 100) / 100; //Number.parseFloat((price - 0.005).toFixed(2));
  return 0;
}
export function PriceFloor(price: number, currency: string) {
  Math;
  if (currency == "TWD") return Math.floor(price + 0.00001);
  else if (currency == "USD") return Math.floor(price * 100 + 0.00001) / 100; //Number.parseFloat((price - 0.005).toFixed(2));
  return 0;
}
export function calDiscount(
  price: number,
  currency: string,
  discountPercent: number
) {
  return PriceFloor(+price * (1 - +discountPercent / 100), currency);
}

export function PriceResolver(price: PriceStoryblok, currency: string) {
  var finalPrice = calDiscount(
    +price[currency],
    currency,
    +price.discountPercent
  );
  return {
    oriPrice: +price[currency],
    finalPrice: finalPrice,
    discount: +price.discountPercent,
  };
}
