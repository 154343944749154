import { StoriesParams, StoryParams } from "storyblok-js-client";
import {
  BundleStoryblok,
  PledgeOptionStoryData,
  ProductStoryData,
  TranslateTableStoryblok,
} from "../StoryBlockSchema/components-schema";
import {
  FetchStoryblokParam,
  sbParams,
  sbsParams,
  storyblokClient,
  storyblokConfig,
} from "./storyblokClient";

//Deprecated?
export async function fetchBundleContent(
  bundle_slug: string,
  locale: string = "default"
) {
  const story = await fetchStory({
    full_slug: "bundles/" + bundle_slug,
    locale: locale,
    resolve_links: "story",
  });
  return story.content as BundleStoryblok;
}
export async function fetchProduct(params: FetchStoryblokParam) {
  //拿到product和price
  const story = await fetchStory(params);
  return story as ProductStoryData;
}
export async function fetchStoryContent<T>(params: FetchStoryblokParam) {
  const story = await fetchStory(params);
  return story.content as unknown as T;
}
export async function fetchStoryData<
  T extends { slug?: string; uuid?: string; content?: any }
>(params: FetchStoryblokParam) {
  let story: any;
  story = await fetchStory(params);
  return story as unknown as T;
}

export async function fetchStoriesData<
  T extends { slug?: string; uuid?: string; content?: any }
>(params: FetchStoryblokParam) {
  const stories = await fetchStories(params);
  return stories as unknown as T[];
}
async function fetchStories(params: FetchStoryblokParam) {
  console.log("[fetchStories] param", sbsParams());

  const pp: StoriesParams = {
    language: params.locale.toLowerCase(),
    ...sbsParams(),
    sort_by: "sort_by_date",
    excluding_fields: "",
  };
  if (params.filter) {
    pp.filter_query = {
      [params.filter.fieldName]: { in: params.filter.fieldValue },
    };
  }
  if (params.with_tag) {
    pp.with_tag = params.with_tag;
  }
  if (params.starts_with) pp.starts_with = params.starts_with;

  return storyblokClient.getStories(pp).then((stories) => {
    return stories.data.stories;
  });
  // return stories.data.stories;
}
//多個才能用excluding_fields

async function fetchStory(params: FetchStoryblokParam) {
  let cv = await storyblokClient
    .get("cdn/spaces/me", {})
    .then((response) => {
      // console.log("Space", response);
      return response.data.space.version;
    })
    .catch((error) => {
      console.log(error);
    });
  let pp: StoryParams = {
    language: params.locale.toLowerCase(),
    ...sbParams(),
    cv: cv,
  };
  var slug = params.full_slug;
  if (params.uuid) {
    pp.find_by = "uuid";
    slug = params.uuid;
  }
  if (params.resolve_links) {
    pp.resolve_links = params.resolve_links;
  }

  if (params.resolve_relation) {
    pp.resolve_relations = params.resolve_relation
      .map((entry) => {
        return `${entry.component}.${entry.field}`;
      })
      .join(",");
  }
  console.log("params", pp, slug);

  try {
    const story = await storyblokClient.getStory(slug, pp);
    return story.data.story;
  } catch (e) {
    console.error("fetch story error", e);

    return null;
  }
}

export async function fetchPledgeData(params: FetchStoryblokParam) {
  const pledge = await fetchStory({
    ...params,
    // resolve_relation: [{ component: "ProductEntry", field: "product" }],
    // resolve_relation: [{ component: "PledgeOption", field: "includes" }],
  });
  // console.log(pledge);
  const pledgeData = pledge as unknown as PledgeOptionStoryData;
  // for(var i = 0; i < objects.length; i++){
  //   var obj = objects[i];
  // convertToNumber(pledgeData);
  // }
  // pledgeData.content.includes = pledge.data.links
  return pledgeData;
}

function convertToNumber(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop) && obj[prop] !== null && !isNaN(obj[prop])) {
      obj[prop] = +obj[prop];
    }
  }
  console.log("Convert:", obj);
}

async function fetchAllLinks(folder: string) {
  let cv = await storyblokClient
    .get("cdn/spaces/me", {})
    .then((response) => {
      console.log("Space", response);
      return response.data.space.version;
    })
    .catch((error) => {
      console.log(error);
    });
  return storyblokClient
    .get("cdn/links/", {
      version: storyblokConfig.version,
      starts_with: folder,
      cv: cv,
    })
    .then((result) => {
      const linkRecord: Record<
        string,
        {
          is_folder: boolean;
          slug: string;
          name: string;
          published: boolean;
          real_path: string;
          uuid: string;
        }
      > = result.data.links;
      return linkRecord;
    });
}

//For Nextjs getStaticPaths
//每個app的Downloads
//撈出對應的slug然後塞到params裡面
export async function fetchAllStoryPaths(
  folder: string,
  routing_slug_tag: string, //FIXME: 什麼意思 給vercel 看的 輸出用的？
  locales: string[],
  option?: {
    paramName;
    paramValue;
  }[]
) {
  //link比較單純不用一整陀
  const linkRecords = await fetchAllLinks(folder);

  let paths: PathParam[] = [];
  //所以的link跑一遍
  Object.values(linkRecords).forEach((record) => {
    console.log("fetchAllLinks", record);

    if (record.is_folder == false) {
      //TODO:拿最後一個
      const splittedSlug = record.slug.split("/").slice(-1)[0];

      //每個語言跑一遍
      for (const locale of locales) {
        var data = {
          params: {
            [routing_slug_tag]: splittedSlug,
            name: record.name,
            real_path: record.real_path,
            uuid: record.uuid,
          },
          locale,
        };

        if (option) {
          data.params[option[0].paramName] = option[0].paramValue;
        }
        paths.push(data);
      }
    }
  });
  return paths;
}

export interface PathParam {
  params: {
    [x: string]: string;
    name: string;
    real_path: string;
    uuid: string;
  };
  locale: string;
}
