//還願實體版
export interface ProductData {
  price: number;
  title: string;
  // description: string;
  taxFree: number;
  tax: number;
}
export interface ProductObject {
  id: string;
  val: ProductData;
}
export class ProductDict {
  static itemData: Record<string, ProductData> = {
    devotion_physical_taiwan_edition_with_OST_bundle: {
      price: 1200,
      taxFree: 1143,
      tax: 57,
      // description: "還願台灣限定實體版+遊戲原聲帶 同捆包",
      title: "還願台灣限定實體版+遊戲原聲帶 同捆包",
    },
    devotion_physical_taiwan_edition: {
      price: 980,
      taxFree: 933,
      tax: 47,
      // description: "還願台灣限定實體版",
      title: "還願台灣限定實體版",
    },
    shippingFee: {
      price: 110,
      taxFree: 105,
      tax: 5,
      // description:"物流費用"
      title: "物流費用",
    },
  };
  static logisticFee: Record<string, number> = {
    CVS: 110,
    HOME: 110,
  };
  static shippingFee = 110;
  static getProducts() {
    const products: ProductData[] = [];
    for (const key in ProductDict.itemData) {
      products.push(ProductDict.itemData[key]);
    }
    return products;
  }
}
