export const Collection_ProjectBacekedSummary = "projectBackedSummary";

export interface ProjectBackedSummary {
  // backer_cnt: number;
  totalAmountTWD: number;
  pledges: {
    [key: string]: BackedEntry;
  };
}
// "/projectBackedSummary/{project_id}/summary"
export interface BackedEntry {
  //總募資結果
  // totalAmount: number;
  backer_cnt: number;
}
