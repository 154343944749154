import { CurrencyExchangeRateStoryblok } from "./StoryBlockSchema/components-schema";
import { fetchStoryContent } from "./Storyblok/fetchStory";

var rate = 0.035;
// var fetched = false;
//這只有前端用而已???
fetchStoryContent<CurrencyExchangeRateStoryblok>({
  full_slug: "data/currency-exchange-rate",
  locale: "default",
}).then((res) => {
  rate = +res.TWD_to_USD;
  // console.log("[All] Convert_USD_To_TWD rate", rate);
});

//TODO: 從database拿?
//定期打api拿資料
//可以上storyblok其實
export function Convert_USD_To_TWD(amount: number) {
  console.log("Convert_USD_To_TWD", rate);

  return Math.round(amount / rate);
}

export function Convert_TWD_To_USD(amount: number) {
  return +(amount * rate).toFixed(2);
}
