import { firestore } from "firebase-admin";
import { ProductDict } from "./ProductData";

//Time stamp 好雷，how to share script
//還願實體版
export type TradeStateEnum =
  | "created"
  | "pending"
  | "paid"
  | "canceled"
  | "failed"
  | "paid_but_err"
  | "paid_and_refunded"; //這整包要上firebase
export type LogisticEnum = "undefined" | "ordered" | "shipped" | "delivered";
export interface TradeBrief {
  uid: string;
  tradeState: TradeStateEnum;
  orderNum: Record<string, number>;
  created: firestore.Timestamp;
}
export class TradeDataConverter {
  static cvsDict = {
    UNIMART: "7-11",
    FAMI: "全家",
    // HILIFE: "萊爾富",
  };
  static cvsText(cvsType: "UNIMART" | "FAMI") {
    // | "HILIFE") {
    return this.cvsDict[cvsType];
  }
  static getLogisticText(tradeData: TradeData) {
    if (tradeData.logisticType === "CVS") {
      return `[${this.cvsText(tradeData.logisticSubType as any)}取貨] ${
        tradeData.CVSStoreName
      }`;
    } else {
      return `[宅配到府]`;
    }
  }
  static toItemData(tradeData: TradeData) {
    const productData = ProductDict.itemData;
    const { orderNum } = tradeData;
    const itemList: {
      key: string;
      name: string;
      number: number;
      price: number;
      totalprice: number;
    }[] = [];
    for (const order in orderNum) {
      const item = {
        key: order,
        name: productData[order].title,
        number: orderNum[order],
        price: productData[order].price,
        totalprice: productData[order].price * orderNum[order],
      };
      itemList.push(item);
    }
    return itemList;
  }
}

export interface TradeData {
  tradeState: TradeStateEnum;
  logisticState: LogisticEnum;
  logisticMsg?: string;
  uid: string;
  orderNum: Record<string, number>; //product_id,count
  itemName?: string;
  totalAmount?: number;
  totalNum?: number; //總共買幾個東西
  createdAt: number;
  paidAt?: number;
  receiverName: string; //收件人 receiverName
  receiverPhone: string; //收件人電話 receiverPhone?
  logisticType: "HOME" | "CVS";
  logisticSubType: string;
  CVSAddress?: string;
  CVSStoreID?: string;
  CVSStoreName?: string;
  Address?: string;
  ZipCode?: string;
  campaign?: string;
  // spToken?: string;
  ECPayTradeNo?: string;
  MerchantTradeNo?: string;
  paymentType: "CREDIT" | "ATM";
  logisticFee?: number;
  receiptType: "B2B" | "B2C";
  receiptID?: string;

  //homePhone:string;
  email: string;
  invoice_mail?: "sent" | "fail";
  buyerName: string;
  shippingNo?: string;
}
export interface TradeObject {
  id: string;
  // static tableURL: string = "trades";
  data: TradeData; // = { uid: "", orderRecord: {} };
}
